/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

window.Vue = require('vue');

//import vue router
/* import VueRouter from 'vue-router'
Vue.use(VueRouter) */

 // vuetify material design
import Vuetify from 'vuetify';

//import speceific icons from fontawesom
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueRecaptcha from 'vue-recaptcha';
library.add(faFacebookF)
library.add(faTwitter)
library.add(faLinkedinIn)
library.add(faEnvelope)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// animate on scroll library
import AOS from 'aos';
import 'aos/dist/aos.css';

// active nav links on scroll
var Scrollactive = require('vue-scrollactive');
Vue.use(Scrollactive);

//A Vue.js component for sharing links on major social networks
var SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing);

Vue.component('vue-recaptcha',VueRecaptcha);

require('./bootstrap');




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/* Vue.component('example-component', require('./components/ExampleComponent.vue').default); */

//vue slick slider
Vue.component('v-slick', require("vue-slick-carousel"));

//start project details component
/* const project = Vue.component('project-details', {
  data () {
    return {

    }
  }
}) */
//end project details component

//Define our routes
/* const routes = [
  { path: '/project', component: project }
] */

//Create the router instance and pass the `routes` option
/* const router = new VueRouter({
  routes 
}) */

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.addEventListener("load", function () {
  //apply vuetify
  Vue.use(Vuetify);

  const app = new Vue({
    el: '#app',

    data: {
      theme: 'dark-theme' //light-theme
    },

    /* router, */
    vuetify: new Vuetify({
      theme: {
        themes: {
          light: {
            primary: '#202a76',
            secondary: '#3dbcd4',
            accent: '#a12272',
          },
        },
      },
    }),

    methods: {
      toggleTheme() {
        if (this.theme == 'dark-theme') {
          this.theme = 'light-theme'
        } else {
          this.theme = 'dark-theme'
        }
      }
    },

    mounted() {
      if (localStorage.theme) {
        this.theme = localStorage.theme;
      }
    },

    watch: {
      theme(theme) {
        localStorage.theme = theme;
      }
    }

  });


  //initaolize animate on scroll plugin
  AOS.init();
});